.page-layout-2columns-left .sidebar {
    z-index: 111;
}

// page-products
.page-products {
    .column.main {
        @include make-col(12);
    }
}
// Layerd Nav default
.manadev-show-more {
    display: none;
}
@include media-breakpoint-up(md) {
    .sidebar-main {
        display: none;
    }

    .main {
        .mana-filter-block-above-horizontal {
            strong {
                @include visuallyhidden;
            }
        }

        .filter-options {
            text-align: center;
        }

        .filter-options-item {
            display: inline-block;
            position: relative;
            z-index: 111;

            &:not(:last-child) {
                margin-right: 16px;
            }
        }

        .filter-options-title {
            border: 1px solid $secondary-colour;
            min-width: 180px;
            height: 50px;
            line-height: 50px;
            font-size: 13px;
            font-weight: bold;
            text-align: center;
            border-radius: 25px;
            cursor: pointer;
            position: relative;
            z-index: 111;
            display: inline-block;
            margin-right: 15px;

            &:after {
                @include font-icon('\e907');
                display: inline-block;
                margin-left: 4px;
                vertical-align: middle;

            }
        }

        .filter-options-content {
            min-width: 276px;
            border-radius: 20px;
            position: absolute;
            top: calc(100% + 16px);
            left: calc(50% - 138px);
            display: none;
            text-align: left;
            border-radius: 25px;
            background-color: #fff;

            &.active {
                display: inline;
            }

            .item {
                height: 53px;
                line-height: 53px;
                padding: 0 18px;
                position: relative;

                a,
                .label {
                    padding-left: 23px;
                    line-height: 16px;
                    display: inline-block;
                }

                &:before {
                    position: absolute;
                    content: "";
                    height: 16px;
                    width: 16px;
                    border: 1px solid #000;
                    left: 18px;
                    top: 15px;
                    border-radius: 2px;
                    background-color: #fff;

                }

                &:after {
                    position: absolute;
                    content: "\2713";
                    font-size: 13px;
                    left: 21px;
                    top: 16px;
                    color: #fff;
                    opacity: 0;
                    z-index: 1;
                    line-height: 16px;
                }

                &:hover {

                    &:before {
                        background-color: $primary-colour;
                    }

                    &:after {
                        opacity: 1;
                    }
                }

                &.mana-selected {

                    &:before {
                        background-color: $primary-colour;
                    }

                    &:after {
                        opacity: 1;
                    }
                }

            }
        }

        .swatch-attribute.color {
            display: block;
            margin: 0;
            background-color: #fff;
            border-radius: 20px;
            padding: 15px 23px;

            .swatch-attribute-options {
                margin: 0;
            }

            a {
                display: inline-block;
                margin: 0 10px 0 0;
            }

            .color {
                width: 28px;
                height: 28px;
                margin: 0;
            }
        }

        .filter-current {
            .items {
                text-align: left;
            }

            .item {
                display: inline-block;
                padding: 2px 20px 0 4px;
                background-color: rgba(0, 0, 0, 0.11);
                border: 1px solid rgba(0, 0, 0, 0.11);
                position: relative;
                margin-right: 5px;

            }

            .remove {
                span {
                    display: none;
                }

                &:after {
                    position: absolute;
                    right: 0;
                    top: 0;
                    padding-top: 4px;
                    height: 100%;
                    width: 16px;
                    @include font-icon('\e924');
                    display: inline-block;
                    margin-left: 5px;
                    background-color: rgba(0, 0, 0, 0.11);
                    text-align: center;

                }
            }
        }

        .filter-actions {
            margin: $margin 0;
            text-align: left;
        }

        .mana-parents {
            display: none;
        }
    }

    .page-wrapper {
        &.filter_hover {
            position: relative;

            &:before {
                content: "";
                position: fixed;
                left: 0;
                top: 0;
                z-index: 11;
                height: 100%;
                width: 100%;
                background-color: rgba(#000, .13);
            }
        }
    }

    .manadev-expandable-filter {
        height: auto !important;
    }
}

@include media-breakpoint-down(sm) {

    // remove scroll on active filter
    body.filter-active .page-wrapper {
        position: fixed;
        overflow: hidden;
        height: 100%;
        left: 0;
        top: 0;
    }

    .main {
        .mana-filter-block {
            //display: none;
        }
    }

    .filter,
    .mana-filter-block {
        text-align: left;
    }

    .filter-content,
    .mana-filter-block {
        position: fixed;
        top: 0;
        left: 0;
        background-color: #fff;
        width: 100%;
        height: 100%;
        z-index: 100;
        visibility: hidden;
        transition: all 1s cubic-bezier(.25, .1, 0, 1.01);
        transform: translate(-100%, 0);
        display: flex;
        flex-flow: column;

        >strong {
            display: block;
            height: 52px;
            text-align: center;
            line-height: 52px;
            border-bottom: 1px solid #e2e2e2;
        }

        &.opened {
            transform: translate(0, 0);
        }

        &.active_bar {
            visibility: visible;
        }
    }

    .mana-parents {
        display: none;
    }

    .mobile-nav-header {}

    .filter-subtitle {
        display: block;
        position: relative;
        text-align: center;
        padding: 15px;
        border-bottom: 1px solid #e2e2e2;

        &:after {
            content: var(--filter-title);
        }

    }

    #sidebar_toggle_close {
        position: absolute;
        left: 15px;
        top: 15px;
        cursor: pointer;
    }

    .icon-close {
        &:after {
            @include font-icon('\e924');
        }
    }

    .filter-title,
    #mana_ajax_wrapper_mobile_mana_layered_nav {
        display: inline-block;
        width: 165px;
        height: 45px;
        border-radius: 23px;
        text-align: center;
        border: $border-default-dark;
        cursor: pointer;
        font-size: 12px;
        padding-top: 10px;

        strong {
            background-color: transparent;
            line-height: 20px;
            color: $primary-colour;
            display: inline-block;
            font-size: 12px;

            &:hover {
                background-color: transparent;
                color: $primary-colour;
            }
        }

        &:before {
            color: $primary-colour;
            font-size: 22px;
            vertical-align: top;
            display: inline-block;
            line-height: 22px;
            margin-right: 6px;
            @include font-icon('\e913');
        }

        &:after {
            content: "Filters";
            line-height: 23px;
        }

        &.active {
            position: fixed;
            z-index: 110;
            top: 15px;
            left: 20px;
            border: none;
            font-size: 12px;
            line-height: 16px;
            padding: 0;
            width: auto;
            height: auto;


            &:before {
                @include font-icon('\e924');
                font-size: 18px;
            }

            &:after {
                content: "Cancel";
                line-height: 20px;
            }

            &.back {
                &:after {
                    content: "Back";
                    line-height: 20px;
                }

                &:before {
                    @include font-icon('\e908');
                }
            }
        }

    }

    .filter-current {
        order: 1;
        padding: 20px 15px;
        border-bottom: 1px solid #e2e2e2;

        .item {
            border: 1px solid #e2e2e2;
            border-radius: 3px;
            display: inline-block;
            padding: 4px 6px;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }

        .filter-label {
            @include visuallyhidden;
        }

        .remove {

            &:before {
                color: $primary-colour;
                font-size: 13px;
                vertical-align: top;
                display: inline-block;
                line-height: 22px;
                margin-left: 4px;
                @include font-icon('\e924');
                top: 2px;
                position: relative;
            }

            span {
                display: none;
            }
        }
    }

    .filter-current-subtitle {
        margin-bottom: 15px;
        display: block;

        &:before {
            color: $primary-colour;
            font-size: 16px;
            vertical-align: top;
            display: inline-block;
            line-height: 22px;
            margin-right: 6px;
            @include font-icon('\e913');
        }
    }

    .filter-actions {
        order: 3;
        position: absolute;
        right: 15px;
        top: 15px;
    }

    .filter-options {
        order: 4;
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        min-height: 460px;
        .filter-options-title {
            padding: 20px 15px;
            transition: all 1s cubic-bezier(.25, .1, 0, 1.01);
            transform: translate(0, 0);
            cursor: pointer;
            display: block;
            border-bottom: 1px solid #e2e2e2;
            position: relative;

            &:after {
                @include font-icon('\e909');
                font-size: 18px;
                top: 21px;
                right: 17px;
                position: absolute;

            }
        }

        &.active {
            .filter-options-title {
                transform: translate(-100%, 0);
                opacity: 1;
                visibility: visible;
            }
        }

        .swatch-attribute-options {
            padding: 10px;
            border: none;
            margin-top: 0;
        }

        .color {
            width: auto;
            line-height: 40px;
            height: 40px;
            min-width: 40px;
            margin-bottom: 10px;
            padding: 0 10px;
            display: inline-block;
            margin-right: 10px;
        }

        .text {
            width: auto;
            line-height: 40px;
            height: 40px;
            min-width: 40px;
            margin-bottom: 10px;
            padding: 0 10px;
            display: inline-block;
            margin-right: 10px;
        }
    }

    .filter-options-content {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
        width: 100%;
        height: 100%;
        z-index: 11111;
        transform: translate(100%, 0);
        visibility: hidden;
        transition: all 1s cubic-bezier(.25, .1, 0, 1.01);
        margin-top: 0;

        &.active {
            visibility: visible;
            transform: translate(0, 0);
        }

        .item {
            padding: 20px 15px;
            border-bottom: 1px solid #e2e2e2;
            position: relative;

            a {
                margin: 0;
            }

            &:after {
                content: "";
                position: absolute;
                height: 16px;
                width: 16px;
                border: 1px solid $primary-colour;
                border-radius: 2px;
                right: $padding;
                top: 20px;
            }

            &:before {
                position: absolute;
                content: "\2713";
                font-size: 13px;
                right: 18px;
                top: 19px;
                color: #fff;
                opacity: 0;
                z-index: 1;
            }

            &:hover {
                &:after {
                    background-color: $primary-colour;
                }

                &:before {
                    opacity: 1;
                }
            }

            &.mana-selected {
                &:after {
                    background-color: $primary-colour;
                }

                &:before {
                    opacity: 1;
                }
            }
        }
    }

    #mana_ajax_wrapper_category_products {
        text-align: left;
    }
}
/* ============================================ *
 * Breadcrumbs
 * ============================================ */
// Breadcrumb size

$breadcrumb_font-size: 14px;
$breadcrumb_font-colour: $font_colour-default;

.breadcrumbs {
	@include make-container;
	margin-top: 15px;
	margin-bottom: 35px;
	text-align: left;
	z-index: 99;
    @include media-breakpoint-up(md) {
        margin-top: 30px;
    }
	ul {
		display: flex;
        justify-content: center;
        
	}
	li {
		font-size: $breadcrumb_font-size;

		a {
			color: $font_colour-default;
			text-decoration: none;
			position: relative;
            color: #c4c4c4;
			&:after {
				@include font-icon('\e909');
				font-size: 12px;	
			}
			
		}
		&:last-child {
			strong { 
				font-weight: normal;
                color: $colour_primary-theme;
			}
		}
	}
}

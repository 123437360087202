/* ============================================ *
* Variables 
* ============================================ */
// Global
$footer_newsletter-subtitle-font-family: "Helvetica", Times, serif; // use from variables.scss
//input
$footer_newsletter-input-height: 53px;
$footer_newsletter-input-font-size: 12px;

//button
$footer_newsletter-btn-height: $footer_newsletter-input-height;
$footer_newsletter-btn-color: #fff;
/* ============================================ *
* Newsletter
* ============================================ */
.newsletter-container {
    @include media-breakpoint-down(md) {
        padding: $padding;
    }
	.sub-title {
		display: block;
		padding-bottom: 14px;
	}
	.declaration {
		padding-top: 15px;
		a {
			text-transform: capitalize;
		}
	}
}
.subscribe-form {
	.newsletter-field {
		display: flex;
		flex-direction: column;
		label {
            @include visuallyhidden;
        }
        input {
        	border: 1px solid #fff;
        	line-height: $footer_newsletter-input-height;
        	height: $footer_newsletter-input-height;
        	font-size: $footer_newsletter-input-font-size;
            color: #c7c7c7;
            padding: 0 20px;
            background: transparent;
        }
        .actions {
            margin-top: 13px;
			button {
				width: 100%;
                border: 2px solid #fff;
	        	span {
	                padding: 0;
	                line-height: 45px;
                    background-color: $footer_newsletter-btn-color;
                    color: #1b1b1b;
                    font-size: 14px;
                    border: 2px solid #1b1b1b;
	        	}        
	        }
	    }
	} 
}
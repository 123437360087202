/* ============================================ *
* Footer
* ============================================ */
// Variables 
// Footer Options 
$footer_cols: 4;
$footer_social_icons: true;
$footer_languages: true;
$footer_backToTop: false;

// Footer Section
$footer_background-color: #1b1b1b;
$footer_border: 1px solid #333;
$footer_padding-top: 40px;
$footer_font-size: 12px;
$footer_title-font-size: 16px;
$footer_color: #bcbcbc;
$footer_title-color: #fff;
$footer_links-color: $footer_color;

/* ============================================ *
* Global
* ============================================ */
.footer-container {

  @include make-fluid-container;

  background: $footer_background-color;
  padding-top: $footer_padding-top;
  color: $footer_color;
  font-size: $footer_font-size;
  text-align: left; 
  
  a {
    color: $footer_links_color;
  }    
  
  // @include media-breakpoint-down(sm) {
  //   padding:0; 
  // }

}
.footer {
  @include make-container;

}
.footer-row {
  @include make-row;
}
.footer-col {  
  @include make-col-ready;
  @include media-breakpoint-down(sm) {
    padding: 0;
  }
  @include media-breakpoint-up(md) {
    @include make-col(12/$footer_cols);
  }
  ul {
    @include media-breakpoint-down(sm) {
      display: none;
      margin: 30px 0;
      padding: 0 14px;
    }
  }
  li {
     margin-bottom: 8px;
  }
  .h3 {
    padding-bottom: 14px;
    position: relative;
    font-size: $footer_title-font-size;
    color: $footer_title-color;
    @include media-breakpoint-down(sm) {
      display: block;
      padding:22px 14px;
      cursor: pointer;
      font-size: 14px;
      border-bottom: $footer_border;
      &:after {
        @include font-icon('\e907');
        position: absolute;
        right: 14px;
        top: 26px;
      }
      &.active {
        &:after {
          transform: rotate(180deg);
          border:0 !important;
        }
      }
    }
  }
  &.last {
    .h3 {
        @include media-breakpoint-up(md) {
            padding-bottom: 5px;
        }
    }
  }
  .toggle-content {
    @include media-breakpoint-down(sm) {
        display: none;
    }
    &.expand {
      display: block;
      transition: all 0.3s ease 0s;
    }
  }
}


/* ============================================ *
* Footer Last 
* ============================================ */
.footer-last {
  padding: 42px 0 20px;
  @include media-breakpoint-up(md) {
    padding: 30px 0;
    margin-top: 55px;
    border-top: $footer_border;
  }
  .footer-row {
     align-items: center;
  }  
  .footer-last-block {
    @include make-col-ready;    
    @include media-breakpoint-up(md) {
      @include make-col(4);
    }
  }
} 
.footer-copyright {
  @include media-breakpoint-down(sm) {
    text-align: center;
    order: 2;
  }
}
.footer-payments {
  display: flex;
  
  li {
    margin:0 10px;      
    
    @include media-breakpoint-up(sm) {
      &:first-child {
        margin-left: 0;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    order: 1;
    margin: 38px 0 86px;   
  }

}
.footer-website {
  text-align: center;
  @include media-breakpoint-down(sm) {
    order: 3;
  }
}
/* ============================================ *
* Socials 
* ============================================ */
@if $footer_social_icons {
  .footer-socials {
    
    display: flex;
    justify-content: end;
    
    @include media-breakpoint-down(sm) {
      justify-content: center;
    }

    .icon {
      font-size: 14px;
        
      &:before { 
        color: #1b1b1b;
        display: inline-block;
        border: $border-default;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        line-height: 25px;
      }
    }

    .insta-icon,
    .youtube-icon {
      &:before {
        content: "f";
      }
    }

    li {
      margin: 0 10px;
      background-color: #fff;
      border-radius: 50%;
      text-align: center; 
      border: $border-default;
      
      @include media-breakpoint-up(md) {
          width: 28px;
          height: 28px;
          &:last-child {
              margin-right: 0;
          }
      }    
      @include media-breakpoint-down(sm) {
        width: 31px;
        height: 31px;
        margin: 0 13px;
      }    
    }
  }
}
/* ============================================ *
 * ScrollToTop (need to remove it)
 * ============================================ */
@if $footer_backToTop {
  .scrollToTop {
    position: absolute;
    bottom: 30px;
    height: 40px;
    right: 30px;
    width: 40px;
    background: #000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    display: inline-block;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    
    visibility: hidden;
    white-space: nowrap;
    color: #fff;
    line-height: 37px;
    z-index: 10;
    text-align: center;
  }

  .scrollToTop.active {
    opacity: 1;
    visibility: visible;
  }

} @else {
  .scrollToTop {
    @include visuallyhidden;
  }

}

/*
01- Home Global
02- Home banner
03- Events section
04- Home categories
05- Masterclasses section 
06- Last section
*/
/* ============================================ *
 * Home Page
 * ============================================ */
.cms-index-index{
    /* =================*
     * 01- Header Global
     * =======================*/
    .column{
        &.main{
             @include media-breakpoint-down(sm) {
                display: flex;
                flex-direction: column;
            }
        }
    }
     /* =================*
     * 06- last section
     * =======================*/
    .last-section {
        @include media-breakpoint-down(sm) {
            order: 2;
            .pagebuilder-column {
                @include make-col-ready;    
                @include make-col(6);   
                &:first-of-type,&:nth-child(2) {
                    margin-bottom: 22px !important;
                }
            }
            img {
                margin-bottom: 8px;
            }
        }
    }
}
 /* =================*
 * 02- Header banner
 * =======================*/
.home-banner {
    @include media-breakpoint-down(sm) {
        min-height: 390px !important;
        padding: 40px 0 30px !important;
        div[data-content-type="buttons"] {
            margin-top: auto;
        }
    }

}
 /* =================*
 * 03- Events section
 * =======================*/
.events-section {
    .pagebuilder-column {
        flex-direction: row !important;
        border: solid 1px #e3e3e3 !important;
        background-color: #f8f8f8;
         @include media-breakpoint-down(sm) {
            padding: 19px !important; 
        }
    }    
    h4,p {
        @include media-breakpoint-up(md) {
            margin-bottom: 7px;
        }
        @include media-breakpoint-down(sm) {
            margin-bottom: 4px;
        }
    }
    div[data-content-type="text"] {
         @include media-breakpoint-down(sm) {
            padding-left: 14px !important;
        }
    }
} 
 /* =================*
 * 04- Home categories
 * =======================*/
.home-categories {
    @include media-breakpoint-up(md) {
         padding-top: 40px;
    }
    @include media-breakpoint-down(sm) {
        padding-top: 22px;
        display: flex;
        overflow-x: auto;
    }
    .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include media-breakpoint-up(md) {
              margin-top: 14px;
        }
        @include media-breakpoint-down(sm) {
            flex-direction: column;
            margin-top: 5px;
        }
    }
    .category-name {
        @include media-breakpoint-up(md) {
            font-size: $font_size-lg;
        }
        @include media-breakpoint-down(sm) {
            font-size: $font_size-default;
        }
    }
    .numb-products {
        color: #c9c9c9;
        @include media-breakpoint-up(md) {
            font-size: $font_size-default;
        }
        @include media-breakpoint-down(sm) {
            font-size: $font_size-sm;
            color: #c1c1c1;
        }
    }
    li {
        padding: 0 10px;
        @include media-breakpoint-down(sm) {
            @include make-col-ready;    
            @include make-col(5);
            padding: 0 10px;
        }
    }
}
.slider {
    position: relative;
    .arrows {
        position: absolute;
        top: -30px;
        right: 0;
        display: flex;
        z-index: 99;
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    .prev-arrow ,.next-arrow{
        width: 29px;
        height: 29px;
        border-radius: 50%;
        border: 1px solid #1b1b1b;       
    }
    .prev-arrow {
        margin-right: 13px;
        &:before {
            content: '\01F890';
            font-size: 18px;
        }
    }
    .next-arrow {
         &:before {
            content: '\01F892';
            font-size: 18px;
        }
    }
}
/* =================*
 * 05- Masterclasses section 
 * =======================*/
.masterclasses-section {
    @include media-breakpoint-up(md) {
        border: solid 1px #e9e9e9;
        background-color: #f8f8f8;
    }
    .pagebuilder-column-group {
        @include make-container;
    }
    .pagebuilder-column {
        @include media-breakpoint-down(sm) {
            flex-basis: initial !important;
            width: 100%  !important;
        }
    }
    ul {
        @include make-row;
        @include media-breakpoint-up(md) {
            justify-content: flex-end;
        }
        @include media-breakpoint-down(sm) {
            flex-wrap: nowrap;
            overflow-x: auto;
            margin-top: 10px;
        }
        li {
            display: flex;
            align-items: center;
            box-shadow: 0 0 10px 5px rgba(224, 224, 224, 0.5);
            background-color: $white-colour;
            padding: 20px 10px 20px 20px;
            margin-bottom: 60px;
            @include media-breakpoint-up(md) {
                @include make-col-ready;    
                @include make-col(4);
            }
            @include media-breakpoint-down(sm) {
                margin-bottom: 0;
                margin-right: 20px;
                min-width: 277px;
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }
    }
    h6 {
        margin-bottom: 7px;
        &:before {
            @include font-icon('\e922');
            font-size: 16px;
            margin-right: 4px;
        }
    }
    .details {
        padding-left: 15px;
        text-align: left;
    }
    .floating-div {
        @include media-breakpoint-up(md) {
            margin-right: 60px;
        }
    }
    .custom-div {
         @include media-breakpoint-up(md) {
             margin-right: 88px;
        }
    }
}
/* =================*
 * 05- food range section
 * =======================*/
.food-range {
    @include media-breakpoint-down(sm) {
        order: 1;
    }
    .order-first-mb{
        @include media-breakpoint-down(sm) {
            min-height: auto !important;
        }
    }
}

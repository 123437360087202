/*
01- about page
02-visit-our-restaurants
03- cms-paul-careers
04-cms paul restaurant
*/
/* ============================================ *
 *  01-about page
 * ============================================ */
.cms-about-us{
  .page-main{
    //  @include make-container;
    }
  .page-title-wrapper{
     text-align: center;
   }
   .column{
      &.main{
        .cms-content-important{
          font-size: $font_size-lg;
          padding: 30px;
          margin: 0;
          margin-bottom: 15px;
         }
         p{
          text-align: left;
          padding: 15px;
          margin-bottom: 15px;
         }
         li{
           text-align: left;
           padding-left: 15px;
           &:before {
             
             padding-right: 15px;
            }
         } 
      } 
    }
}
/* ============================================ *
 *  02-visit-our-restaurants
 * ============================================ */
.cms-visit-our-restaurants {
    .advantages-list,.advantages-list2 {
        li {
            &:before {
                @include font-icon('\e90b');
                font-size: 12px;
                margin-right: 5px;
            }
        }
    }   
}
/* ============================================ *
 *  03- cms-paul-careers
 * ============================================ */
.cms-paul-careers {
    .images-section {
         overflow-x: auto;
        .row-full-width-inner {
            display: flex;
            @include media-breakpoint-up(md) {
                margin: 0 -224px;
            }
            @include media-breakpoint-down(sm) {
                margin: 0 -130px;
            }
        }
        figure {
            @include make-col-ready;    
            @include make-col(5); 
            @include media-breakpoint-down(sm) {
                padding: 0 5px !important;
            }
        }
    }
    .principles-section {
        .pagebuilder-column {
            @include media-breakpoint-down(sm) {
                margin: 0 5px 10px 5px !important;
                width: 47% !important;
                max-width: 47% !important;
            }
        }
    }
    .opinions-slider  {
        @include media-breakpoint-down(sm) {
            margin-bottom: 70px !important;
        }
        img {
            margin: auto;
            border-radius: 50% !important;
        }
        .slick-dots {
            li {
                margin: 0;
                button {
                    &:before {
                        font-size: 8px;
                    }
                }
            }
        }
    }
   
}
/* ============================================ *
 *  04-cms paul restaurant
 * ============================================ */
.cms-paul-restaurant {
     .breadcrumbs {
         @include media-breakpoint-down(sm) {
            background-color: #f8f8f8;
            margin: 0;
            padding-top: 20px;
         }
    }
    div[data-content-type="row"] {
        @include media-breakpoint-down(sm) {
            padding: 0 !important;
        }
    }
    .reviews-section {
        @include media-breakpoint-down(sm) {
            background-color: #f8f8f8;
        }
    }
}
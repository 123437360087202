
// Variables 

$list_item-columns-lg: 4;
$list_item-columns-md: 3;
$list_item-columns-sm: 6;


/* ============================================ *
 * Main Container
 * ============================================ */
.catalog-category-view {     
  .columns {
    margin-bottom: 60px;
  }

/* ============================================ *
 * Category Header
 * ============================================ */
  .page-title-wrapper {
    .page-title {
      text-align: center;
      margin-bottom: 20px;
    }
  } 
  .category-description {
    @include make-col-ready;
    @include make-col($list_item-columns-sm);
    text-align: center;
    margin: 0 auto 50px;
    padding: 0;
    font-size: $font_size-md;
  }
  .morecontent {
    span {
      display: none;
    } 
    .morelink {
      display: inline-block;
      font-weight: bold;
      text-decoration: underline;
    }
  }

/* ============================================ *
 * Category Item Image
 * ============================================ */  
  .product-item-photo {
    position: relative;
    .product-image-container {
      display: inline-block;
      max-width: 100%;
    }
    .product-image-wrapper {
      display: block;
      height: 0;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }
    .product-image-photo {
      display: block;
      margin: auto;
      max-width: 100%;
      height: auto;
      position: absolute;
      bottom: 0;
      top: 0;
      right: 0;
      left: 0;
    }
  }

/* ============================================ *
 * Catalog Image Options
 * ============================================ */
  .top-option {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 9;
    padding: 10px;
    text-align: center;
    .details {
      display: block;
      width: 117px;
      height: 24px;
      line-height: 24px;
      background: #C8C8C8;
      border-radius: 14.5px;
      font-size: $font_size-default;
      color: $white-colour;
    }
    .delivery-only {
      margin-left: 10px;
      &:before {
        content: "";
        display: inline-block;
        background: $white-colour;
        width: 14px;
        height: 11px;
        margin-right: 5px;
      }
    }
    .new-label {
      width: 61px;
      background: $colour_primary;
    }
  }
}

/* ============================================ *
 * Category Top
 * ============================================ */
.category-view {
  text-align: left;

  .category-description {
  }
}

/* ============================================ *
 * Category Grid
 * ============================================ */
.products-grid {

  li.product-item {
    @include make-col-ready;
    @include make-col($list_item-columns-md);

    @include media-breakpoint-up(sm) {
      @include make-col($list_item-columns-md);
    }

    @include media-breakpoint-up(md) {
      @include make-col($list_item-columns-md);
    }

    .product-item-info {
      padding-bottom: 30px;
    } 
  }

  .product-item-details {
    position: relative;
    top: 0;
    z-index: 1;
    background: $white-colour;
    padding-top: 15px;
    -webkit-transition: all .3s;
    transition: all .3s;
    height: 150px;
    text-align: left;
    .product-item-name {
      margin: 0 0 15px;
    }
    .price {
      font-size: $font_size-lg;
    }
  }
  li.product-item { 
    &:hover {
      .delivery-only { 
        background: $colour_primary;
      }
      .product-item-details {
        top: -55px;
      }
      .product-item-name {
        text-decoration: underline;
      }
    }
  }


  .product-items {
    @include make-row;

    // .swatch-option.text {
    //   width: 30px;
    //   height: 30px;
    //   line-height: 28px;
    //   border: 1px solid $primary-colour;
    //   background:$secondary-colour;
    //   color: $primary-colour;
    // }
  }

  .product-item-inner {
    margin-top: -5%;
    -webkit-transition: all .3s;
    transition: all .3s;
    opacity: 0;

    .swatches-box {
        margin-bottom: 5px;
    }
    button.action {
      width: 100%;
    }
    .action.towishlist {
    //  @include font-icon-before('\e90e');
    //  span {
    //   font-size: 0;
    //  }
    } 
  }

  li.product-item { 
    &:hover {
      .product-item-inner {
        margin-top: 10px;
        opacity: 1;
      }
    }
  }
  .split.wishlist {
  //  display: flex;
  //  flex-wrap: wrap;
  //  position: relative;
  //  &.active ul.items {
  //    display: block;
  //  }
  //  button{
  //    &.split {
  //      width: 48%;
  //    }
  //    &.change {
  //      width: 46%;
  //      margin-left:  auto;
  //    }
  //  }
  //  span {
  //    font-size: 12px;
  //  }
  //  ul.items {
  //    display: none;
  //    width: 95%;
  //    background: #b7b5b5;
  //    z-index: 100;
  //    position: absolute;
  //    top: 100%;

  //  }
  //  li.item {
  //    margin: 0;
  //    line-height: 30px;
  //    padding: 2px 5px;
  //    &:last-child {
  //      background: #000;
  //      color: #fff;
  //    }
  //    &:hover {
  //      opacity: 0.6;
  //      cursor: pointer;
  //    }
  //  }
  }
}   

/* ============================================ *
 * Category List
 * ============================================ */
.products-list {
  li.product-item {
    @include make-col-ready;
    margin-bottom: 20px;
    border: $border-default;
    border-color: #F3F3F3;
    padding: 0px;
    .product-item-info {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      flex-direction: row;
    }
  }

  .product-item-img {
    @include make-col-ready;
    @include make-col($list_item-columns-md);
    text-align: left;
    padding: 0;
  }

  .top-option {
    .details {
      background: $colour_primary;
    }
  }

  .details.product-item-details {
    @include make-col-ready;
    @include make-col($list_item-columns-sm);
    padding-left: 0;
    text-align: left;
    .suitable-for {
        color: #BCBCBC;
        margin-bottom: 20px;
        margin-top: 2px;
    }
  }

  .allergens-box {
    margin-bottom: 20px;
    p {
      font-weight: bold;
      margin-bottom: 2px;
    }
  }

  .swatches-box {
    margin-bottom: 5px;
    font-weight: bold;
  }

  .product-item-inner {
    @include make-col-ready;
    @include make-col($list_item-columns-md);
    padding-right: 30px;
    padding-left: 0;
    .list-price {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 50px;
    }
    .price {
      font-size: $font_size-lg;
    }
    .per-item {
      font-size: $font_size-lg;
      margin-left: 5px;
      line-height: 1;
    }
    button.action {
      width: 100%;
    }
  }
  .quantity-wrap {
    justify-content: flex-end;
    margin-bottom: 20px;
  }

}


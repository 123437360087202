

/* ============================================ *
 * Load
 * ============================================ */
@import 'var/variables.scss';

@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/tools/normalize.scss'; // normalize
@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/tools/print.scss'; // print
@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/tools/clearfix.scss'; 


@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/tools/grid/grid.scss'; // bootstrap grid

// fonts

@import "../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/tools/mixins/_font-style.scss";
@import 'var/fonts.scss'; // Fonts and Headings
@import "../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/tools/mixins/_font-icons.scss";
@import 'var/icons.scss'; // icons




@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/tools/mixins/_visuallyhidden.scss';
@import 'tools/reset.scss'; // reset
@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/tools/modals.scss';
@import 'tools/buttons.scss';
@import 'tools/forms.scss';
@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/tools/tables.scss';
@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/tools/tabs.scss';






/* ============================================ *
 * Layout
 * ============================================ */
@import 'layout/header.scss';
@import 'layout/navigation.scss';
@import 'layout/breadcrumbs.scss';
@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/layout/toolbar.scss';
@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/layout/messages.scss';
@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/layout/page.scss';
@import 'layout/home.scss';

@import 'layout/footer.scss';

/* ============================================ *
 * Modules
 * ============================================ */
@import 'modules/page-builder.scss';
@import 'modules/cms.scss';
@import 'modules/blog.scss';
@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/modules/theme.scss';

/* Catalog */
@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/modules/catalog/catalog.scss';
@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/modules/catalog/price.scss';
//@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/modules/catalog/swatches.scss';
//@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/modules/catalog/catalog-layerdnav.scss';
@import 'modules/catalog/catalog-list.scss';
@import 'modules/catalog/catalog-product.scss';
@import 'modules/catalog/catalog-layerdnav.scss';
@import 'modules/catalog/swatches.scss';


/* customer */
@import 'modules/customers/customers.scss';


/* Chekcout */
@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/modules/checkout/cart.scss';
@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/modules/checkout/minicart.scss';
@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/modules/checkout/checkout.scss';
@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/modules/checkout/multishipping.scss';



@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/modules/review.scss';
@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/modules/wishlist.scss';
@import 'modules/newsletter.scss';
@import 'modules/contact.scss';


/* Extra *
//@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/modules/manadev.scss';

/* ============================================ *
 * Lib Files
 * ============================================ */      

@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/lib/slick/slick.scss';
@import '../../../../../../../vendor/bestresponsemedia/themeboilerplate/web/css/lib/slick/slick-theme.scss';
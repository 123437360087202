/*
01- Header Global
02- Before Header - Top Message
03- Header
04- Header Search
05- Header Cart // review ++++++++++++++++++
06- Header Account Links // review ++++++++++++++++++
07- Header After
*/

/* ============================================ *
 * 01- Header Global
 * ============================================ */

// mobile start with @include media-breakpoint-down(sm) 

.page-header {

    background: $header-background-colour;
    position: relative; // DO NOT CHANGE 
    padding: 0;

    @include media-breakpoint-down(sm) {
        border-top: 5px solid #1a1a1a;
    }
}

/* ============================================ *
 * 02- Before Header - Top Message
 * ============================================ */
.before-header {
    background: $colour_primary-theme;
    padding: 15px 0;
    
    // hide on mobile
    @include media-breakpoint-down(sm) {
        @include visuallyhidden;
    }
}

.header-top-message {
    @include make-container();

    text-align: center;


    color: $white-colour;
    font-size: $font_size-sm;

    a {
       color: $white-colour;
       text-decoration: underline;
    }

    .header-top-row {
        @include make-row;  
    }
    .header-message {
        @include make-col-ready;
        @include make-col(6);

        text-align: left;
    }
    .header-top-controls {
        @include make-col-ready;
        @include make-col(6);

        text-align: right;
        
        li {
            display: inline-block;
            margin-left: 32px;
            
            &:first-child {
                margin-left: 0;
            }
            
            a {
                &:before {
                    content: '';
                    width: 16px;
                    height: 14px;
                    background-color: $white-colour;
                    display: inline-block;
                    margin-right: 7px;
                    vertical-align: middle;
                }
            }
        }
    }
}


/* ============================================ *
 * 03- Header
 * ============================================ */
.header { 

    padding: 25px 0;

    position: relative;

    .header-top {
        @include make-container();
    }

    .header-row {
        @include make-row;
    }

    .logo {

        @include make-col-ready;
        @include make-col(6);
        @include media-breakpoint-up(md) {
            @include make-col(2);
        }
        
        // logo vertical aligment
        display: flex;
        align-items: center;
        justify-content: center;
        
        justify-content: flex-start;
        
        .h1,
        h1 {
            display: flex;
            align-items: center;
        }
        img {
            max-width: 133px;
            @include media-breakpoint-down(md) {
                max-width: 86px;   
            }
        }
    }

    .skip.contentarea {
        @include visuallyhidden;
    }
    
}

.header-controls {
    
    @include make-col-ready;
    @include make-col(6);
    display: flex;
    justify-content: end;
    

    @include media-breakpoint-up(md) {
        //@include make-col(3);
        @include make-col(2);

    }
    @include media-breakpoint-up(lg) {
        @include make-col(4);

    }


    text-align: right;
    
    ul {
       
       display: flex;
       justify-content: flex-end;

    }

    li {
        display: flex;
        align-items: center;

        margin-left: 25px;
        
        &:first-child {
            margin-left: 0;
        }
    }
    .link-cart {

    }
    .link-search {

    }
    .link-location {
        @include media-breakpoint-down(md) {
            display: none; 
        }

        a {
            font-size: $font_size-md;

            &:before {
                margin-right: 8px;
                @include font-icon('\e915');
            }
        }
       
    }
}


.nav-toggle {} // moved to navigation.scss

/* ============================================ *
 * 04- Header Search
 * ============================================ */
.mobile-search-icon {
    display: none;
    cursor: pointer;
    @include media-breakpoint-down(md) {
        display: block;
    }
}

.header-search { 
    
    .control {
        display: flex;

        border: $border-default;
        border-radius: 20px;
        overflow: hidden;
        padding: 0 15px;

        @include media-breakpoint-up(md) {
            max-width: 178px;
        }
        
    }
    .action {
        margin-right: 8px;
    }
    
    button {
        border: 0 none;
    }
    
    input {

        height: 40px;
        border: none;
        
        font-size: 12px;
        padding: 0;

    }

    
    .search-icon {
        margin: 0;
        padding: 0;
        background: none;
        border: 0 none;
        color: $colour_primary-theme;
        line-height: inherit;

        &:before {
            @include font-icon('\e928');
            font-size: 18px;
            line-height: 35px;
        }
    }
 


    @include media-breakpoint-down(md) { // review
        display: none;
        position: absolute;
        top: 58px;
        left: 0;
        right: 0;
        overflow: hidden;
        padding: 15px;
        background: $colour_primary-theme;
        z-index: 999;
    }
    
}


/* ============================================ *
 * 05- Header Cart
 * ============================================ */
.minicart-wrapper {
    .action.showcart {
        display: flex;
        line-height: 1;
    }
    .counter {
        position: relative;
        &:before {
            @include font-icon('\e91e');
            // position: absolute;
            // top: 0;
            // left: 0;
        }

        
        display: inline-block;
        
        
        text-align: center;
        
    }
    li {
        &.product-item {
            max-width: 100%;
        }
    }
    .ui-dialog {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 910;
        background-color: #fff;
        width: 25%;
        max-width: 470px; 
        box-shadow: 0 0 20px 0 rgba(0,0,0,0.25);
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        
        @include media-breakpoint-down(md) {
            margin: 0 auto;

            left: 0;
            top: 95px;
            right: 0;
            bottom: 25px;
            width: 100%;
            max-width: 345px;
        }
        
        &.active {
            display: flex;
        }
    }
}

// when cart is open remove page scroll - 
.noscroll {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
    left: 0;
    top: 0;
}

#header-cart-over {
    position: fixed;
    z-index: 900;
    top: 0;
    left: 0;

    display: block;
    visibility: hidden;

    width: 100%;
    height: 100%;

    -webkit-transition: all .5s cubic-bezier(.645, .045, .355, 1);
    -moz-transition: all .5s cubic-bezier(.645, .045, .355, 1);
    transition: all .5s cubic-bezier(.645, .045, .355, 1);
    opacity: 0;
    background: $primary-colour;
    &.active {
        visibility: visible;
        -webkit-transition: all .5s cubic-bezier(.645, .045, .355, 1);
        -moz-transition: all .5s cubic-bezier(.645, .045, .355, 1);
        transition: all .5s cubic-bezier(.645, .045, .355, 1);
        opacity: 0.2;
    }
}

/* ============================================ *
 * 06- Header Account Links
 * ============================================ */
#header-links { 
    display: none;
    position: absolute;
    top: 100%;
    right: 20px;
    background: $primary-colour;
    z-index: 995;
    text-align: left;

}
ul.header.links {
    display: none;
    position: absolute;
    top: 30px;
    left: -65px;
    
    //padding: 15px 0;
    width: 150px;
    background: #fff;
    z-index: 9999;
    text-align: left;
    font-size: $font_size-sm;

    border: 1px solid #ccc;
    &:after,
    &:before {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
        border-width: 10px;
        margin-left: -10px;
    }
    &:before {
        border-color: rgba(0, 0, 0, 0);
        border-bottom-color: #ccc;
        border-width: 11px;
        margin-left: -11px;
    }
    li {
        width: 100%;
        a {
            display: block;
            padding: 5px 10px;
            &:hover {
                background: #eee;
            }
        }
        &:last-child {
            a {
                //padding-bottom: 0;
            }
        }
    }
}

.link-account {
    position: relative;
    &.active {
        ul.header.links {
            display: block;
        }
    }
}


/* ============================================ *
 * 07- Header After
 * ============================================ */
.header-after-message {
    padding: 15px 0;
    border-top: $border-default;
    border-bottom: $border-default;
    border-color: $border_colour-light;
}
.header-pos {
    @include make-container();

    text-align: center;
    display: flex !important;
    justify-content: center;

    li {
        margin-right: 50px;
        &:last-child {
            margin-right: 0;
        }

        [class^='icon-'] {
            background: #f00;
            margin-right: 5px;
        }

        // Mobile
        @include media-breakpoint-down(sm) {
            margin: 0;
            &:first-child,
            &:last-child {
                display: none;
            } 
        }
    }
}




.desktop { // ??????
    @include media-breakpoint-down(md) {
        display: none;
    }
}

/* ============================================ *
 * Default
 * ============================================ */
button,
a.action.primary,
a.action.back {
	overflow: visible;
	width: auto;
	border: 0; // reset
	border: $button-border-size solid $button-border-color;
	padding: 0;
	margin: 0;
	background: transparent;
	cursor: pointer;
	color: $button-color;
	vertical-align: middle;

	///////
	display: block;
	///////
    span { 
    	display: inline-block;
		width: 100%;
		padding: 0 $button-padding;
		
		white-space: nowrap;
		transition: $button-transition;
		
		// Text Styles
		text-align:center;
		text-transform: $button-text-transform;
		
		color: $button-color;
		font-size: $button-font-size;
		line-height: $button-line-height;

		// Custome Styles
		border: 2px solid #fff;
		background: $button-background;
	}

	&.disabled,
    &[disabled] {
    	cursor: default;
    	pointer-events: none; // Disabling clicks

    	span {
			background: $button-disabled-background;
			border-color: $button-disabled-border-color;
			color: $button-disabled-font-color;
    	}
    }

    &:hover {
		
    	span {
    		border-color: $button-hover-border-color;
    		color: $button-hover-color;
			background: $button-hover-background;
			transition: $button-transition;
			
    	}
    }

	&.small {
		max-width: $button-small-max-width;
		font-size: $button-small-font-size;
		width: 100%;
	}
	&.medium {
		max-width: $button-medium-max-width;
		font-size: $button-medium-font-size;
		width: 100%;
	}
	&.large {
		max-width: $button-large-max-width;
		font-size: $button-large-font-size;
		width: 100%;
	}
	&.active {
		span {
			color: $button-hover-color;
			background: $button-hover-background;
		}
		&:hover {
			span {
				color: $button-color;
				background: $button-background;
			}
		}
	}

	// Custome Buttons
    .btn-checkout span {

    }
    
    button.btn-checkout.no-checkout {

	}
}
/* ============================================ *
 * Custom Buttons
 * ============================================ */
	

 /* ============================================ *
 * Primary button
 * ============================================ */
 
.primary{
	// button { 
	// 	color: $button-color;
	// 	background: $button-background;
	// 	height: $button-height;
	// 	line-height: $button-height;
	// 	transition: $button-transition;
 //        &:hover {
 //            color: $button-hover-color;
 //            background: $button-hover-background;
 //            transition: $button-transition;
 //        }
 //        span {
 //        	color: $button-color;
 //            &:hover {
 //                color: $button-hover-color;
 //                background: $button-hover-background;
 //            }
 //            &.disabled,
 //            &[disabled] {
 //                cursor: default;
 //                pointer-events: none;
 //            }

 //            &.active {
 //                color: $button-hover-color;
 //                background: $button-hover-background;
 //            }
 //    	}

 //    	@include media-breakpoint-down(sm) {
 //    		width: 100%;
 //    	}	
	// }
}

/* ============================================ *
* Secondary button
* ============================================ */

.secondary {
    button { 
        
        color: $button-secondary-colour;
        background: $button-secondarty-background;
        
        span {
			
			color: $button-secondary-colour;
			font-size: $button-font-size;
        	background: $button-secondarty-background;
        	
        	&:hover {
            	background: transparent;
            	color: $button-secondary-colour;
          	}
		}
    }

    a {
		color: $button-secondary-colour;
		font-size: $button-font-size;
		// font-weight: $font-weight-bold;
	}
	 
}
/* ============================================ *

1- Blog Layout 
2- List Page
3- Post Page

 * ============================================ */


/* ============================================ *
 * 1- Blog Layout
 * ============================================ */
 
 .is-blog {
 	.page-main {
		@include make-container();
	}
 }

 /* ============================================ *
 * 2- List Page
 * ============================================ */
.wordpress-homepage-view {
 	.page-title-wrapper {
 		margin-top: 10px;
 		.page-title {
 			text-align: center;
 		}
 	}
 	.post-list-description {
 		font-size: $font_size-md;
 	}
 	.blog-search {
 		position: relative;
	    width: 275px;
	    margin: 30px auto;
	    input {
	    	border-radius: 20px;
	    	height: 45px;
	    	font-size: $font_size-default;
	    	padding-left: 45px;
	    }
	    button {
	    	position: absolute;
		    top: 14px;
		    left: 10px;
		    width: 16px;
		    height: 16px;
		    border: none;
	    }
	    .search-icon {
	    	margin: 0;
	        padding: 0;
	        background: none;
	        border: 0 none;
	        color: $colour_primary-theme;
	        line-height: 25px;
		    &:before {
	            @include font-icon('\e928');
	            font-size: 18px;     
        	}
	    }
	} 
 	.top-categories-title {
		font-size: $font_size-lg;
 	}
 	.top-categories-list {
 		margin: 30px -15px 50px;
 		li {
 			@include make-col-ready;
 			&:hover {
				.text-box {
					background: $white-colour; 
					color: $colour_primary;
				}
			}
 		}
 		.image-box {
		   	position: relative;
			height: 144px;
			cursor: pointer;
		}
		.text-box {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0 auto;
			color:$white-colour;
			font-size: $font_size-md;
			height: 55px;
			padding: 10px 0;
		}
 		.categories-posts {
			font-size: $font_size-sm;
 		}
 	}
 	.post-list-wrapper {
	 	.post-list {
	 		@include make-row;
	 	}
	 	li {
		 	&.item {
		 		@include make-col-ready;
		        @include make-col(4);
		        text-align: left;
		        margin-bottom: 60px;
		    }
		    &.featured-post {
		    	@include make-col(12);
		    	order: -1;
 				margin-bottom: 50px;
		    }
	 	}
	 	.post-meta {
		    font-size: $font_size-default;
		    margin: 20px 0 15px;
		}
		h4 {
			margin-bottom: 15px;
		}
		.post-excerpt {
			font-size: $font_size-default;
		}
 	}

 	.featured-post {
 		.post-entry {
			@include make-row;
 		}
 		.post-image {
 			@include make-col-ready;
		    @include make-col(7);
 		}
 		.post-detials {
 			@include make-col-ready;
		    @include make-col(5);
		    text-align: left;
		    display: flex;
		    flex-direction: column;
		    -webkit-box-pack: center;
		    -webkit-justify-content: center;
		    -ms-flex-pack: center;
		    justify-content: center;
 		}
 		h4 {
 			 font-size: 25px;
 			 margin: 30px 0 20px;
 		}
 		.post-excerpt {
 			font-size: $font_size-md;
 			margin-bottom: 50px;
 		}
 		.post-time {
 			position: relative;
 			font-size: $font_size-default;
 			padding-left: 15px;
 			&:after {
				position: absolute;
				content: "";
				width: 12px;
				height: 12px;
				background: $colour_primary;
				top: 2px;
				left: 0;
 			}
 		}
 	}
/* ============================================ *
 * Load More Style
 * ============================================ */
	.pager {
		@include visuallyhidden();
	}
 	.jscroll-inner  {
		.pager {
			display: block;
			clear: both;
			text-align: center;
			.label,
			span {
				@include visuallyhidden();
			}	
			li.item {
				&:not(.pages-item-next) {
					@include visuallyhidden();
				}
			}
		}
		.toolbar-amount,
		#paging-label {
			@include visuallyhidden();
		}
		li.item {
			&.pages-item-next {
				display: block; 
				width: 275px;
			    height: 53px;
			   	line-height: 45px;
			   	border: $border-default;
				border-width: 2px;
			   	padding: 0;
			   	margin: 0 auto $margin*4;
			} 
			.next {
				display: block;
				margin: 0 auto;
				background: $colour_primary;
				color: $white-colour;
			    font-size: $font_size-default;
			    border: $border-default;
				border-width: 2px;
				border-color: $white-colour;
				letter-spacing: 0.1px;
			    text-align: center;
				&:after {
					content: "Show More";  
				}													
			}	
		}
	}
}

 /* ============================================ *
 * 3- Post Page
 * ============================================ */
.wordpress-post-view {
	.page-title {
	    margin: 10px 0;
	    text-align: center;
	}
	.under_title_description,
	.post-meta,
	.post-content {
		@include make-col-ready;
		@include make-col(8);
		margin: 0 auto;
		padding: 0;
		p {
			font-size: $font_size-md;
 			margin-bottom: 15px;
 			text-align: center;
		}
	}
	.post-meta {
		margin: 0 auto 30px;
		font-size: $font_size-default;
	}
	.post-content {
		margin: 75px auto 0;
		text-align: center;
		h2, h3, h4,
		h5, h6 {
			margin-bottom: 30px;
		}
		ul, ol{
			list-style: disc;
		    padding: 45px;
		    text-align: left;
		    li {
		    	font-size: $font_size-md;
		    	padding-bottom: 15px;
		    }
		}
		ol {
			list-style: decimal;
		}
		.blocks-gallery-grid {
			width: 100%;
			padding: 0;
			li { 
				margin-bottom: 0px;
				padding: 0;
			}
		}
	}
	.wp-block-image {
		img {
			width: 100%
		}
	}
	a.action.primary {
	    width: 275px;
	    margin: 15px auto 0;
	}
	.social-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 30px auto 45px;
		li {
		    width: 36px;
		    height: 36px;
		    line-height: 36px;
		    border: $border-default;
		    border-radius: 25px;
		    margin-right: 10px;
		    a {
		    	display: block;
		    	color: $colour_primary;
		    }
		}
	}
}

 /* ============================================ *
 * Related Product
 * ============================================ */

 .post-related-product {
 	position: relative;
 	margin-bottom: 75px;
 	h2 {
 		margin-bottom: 30px;
 	}
 	li {
	 	img {
	 		filter: grayscale(1);
	 		transition: all .5s;
	 	}
	 	&:hover {
			img {
				filter: grayscale(0);
			}
	 	}
 	}
 	.product-item-details {
 		text-align: left;
 		color: $colour_primary;	
 		strong {
 			margin: 15px 0;
 			display: block;
 			font-size: $font_size-lg;
 		}
 		.price {
			font-size: $font_size-lg;
 		}
 	}
 	.arrows {
        position: absolute;
        top: 10px;
        right: 12px;
        display: flex;
        z-index: 99;
    }
    .prev-arrow,
    .next-arrow {
        width: 29px;
        height: 29px;
        border-radius: 50%;
        border: $border-default;   
        cursor: pointer;   
    }
    .prev-arrow {
        margin-right: 13px;
        &:before {
            content: '\01F890';
            font-size: 18px;
        }
    }
    .next-arrow {
        &:before {
            content: '\01F892';
            font-size: 18px;
        }
    }
 }
/* ============================================ *
 * Contact Page
 * ============================================ */
.contact-index-index{
    .page-title {
        margin: 25px 0 0;
        text-align: center;
        @include media-breakpoint-down(sm) {
            font-size: 25px;
        }
    }
    .columns {
         @include make-container;
    }
    .column {
        &.main {
            @include make-row;
            @include media-breakpoint-up(md) {
                padding-bottom: 83px;
            }
            @include media-breakpoint-down(sm) {
                padding-bottom: 50px;
            }
        }
    }
    .column:not(.sidebar-additional){
      .form{
         &.contact{
           @include make-col-ready;
           @include media-breakpoint-up(md) {
             @include make-col(4);
           }
           @include media-breakpoint-down(sm) {
             @include make-col(12);
           }
         }
     }
    .field {
        @include media-breakpoint-up(md) {
            &.name {
                width: 48%;
                float: left;
            }
            &.firstname {
                margin-right: 14px;
            }
        }
        &.comment {
            position: relative;
            label {
               position: absolute;
               left: 18px;
               text-align: left;
               top: 9px;
            }
        }
    }
    button {
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
        span {
            padding: 0 84px;
        }
     }
   }
    .contact-block {
          @include make-col-ready;
          @include media-breakpoint-up(md) {
             @include make-col(6);
             @include make-col-offset(1);
          }
          @include media-breakpoint-down(sm) {
             @include make-col(12);
         }
        .contact-info {
            @include media-breakpoint-up(md) {
                display: flex !important;
                flex-direction: row !important;
                align-items: center;
            }
            @include media-breakpoint-down(sm) {
                align-items: flex-start;
            }
            &:before {
                @include font-icon('\e914');
                @include media-breakpoint-up(md) {
                    font-size: 28px;
                    margin-right: 10px;
                }
                @include media-breakpoint-down(sm) {
                    margin-bottom: 10px;
                    font-size: 20px;
                }
            }
        }
        .pagebuilder-column-group {
            @include media-breakpoint-down(sm) {
                flex-wrap: nowrap;
            }
        }
    }
}
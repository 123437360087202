
/* ============================================ *
 * Variables
 * ============================================ */

$modal_background-color: $body-background-colour;
$modal_box-shadow: 0 0 12px 2px rgba(0, 0, 0, .35);

$modal-popup_padding: 15px;
$modal-popup_width: 75%;


body {
    &._has-modal {

        // position: fixed;
        // overflow-y: scroll;
        // width: 100%;
        // left: 0;
        // top: 0;
    }
}
.modals-wrapper {

    .modals-overlay {
        background-color: rgba(51, 51, 51, 0.55);
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 990 !important;
    }    
}

.modal-popup {
    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 0s .3s, opacity .3s ease;
            transition: visibility 0s .3s, opacity .3s ease;

    &._show {
        z-index: 999 !important;
        visibility: visible;
        opacity: 1;
        -webkit-transition: opacity .3s ease;
                transition: opacity .3s ease;

        .modal-inner-wrap {
            background: $modal_background-color;
            padding: $modal-popup_padding;
            opacity: 1;
            -webkit-transform: translateY(0);
                    transform: translateY(0);
        }

    }

    .modal-inner-wrap {
        opacity: 0;
        pointer-events: auto;
        
        margin: 60px auto;
        width: 70%;
        max-width: 550px;


        display: flex;
        flex-direction: column;

        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        
        -webkit-transform: translateY(-200%);
                transform: translateY(-200%);
        -webkit-transition: -webkit-transform 0.2s ease;
                transition: transform .2s ease;
    }

    // Modal Header
    .modal-header {
        flex: 1;
        padding: 0 15px 15px;
        position: relative;
        
        @include heading-2;

        &:after {
            display: none !important;
        }
    }
    .action-close {
        position: absolute;
        right: 0;
        top: 0;

        span {
            background: none;
            border: 0;
            line-height: inherit;
            margin: 0;
            padding: 0;
            font-size: 0;
            &:before{
                @include font-icon('\e924');
                font-size: 18px;
                color: #000;
            }
            
        }
    }

    // Modal content
    .modal-content {
        flex: 1;
        padding: 30px 0;
        text-align: left;
    }
    
    // Modal Footer
    .modal-footer {
        margin-top: auto;
        button {
            span {
                background: none;
                padding: 5px 20px;
                margin: 0;
                border: 1px solid #000;
                color: #000;
                line-height: inherit;
            }
        }
    }

    //  If applied, switching outer popup scroll to inner
    &._inner-scroll {
        overflow-y: visible;

        .ie11 & {
            overflow-y: auto;
        }

        .modal-inner-wrap {
            max-height: 90%;
            .ie11 & {
                max-height: none;
            }
        }

        .modal-content {
            overflow-y: auto;
        }
    }
}



/* ============================================ *
 * Global tabs
 * ============================================ */
// .detailed {

//   .data {
//     &.items {
//       @include make-row();

//     }
//   }

// }






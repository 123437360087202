
$nav_font-family: $font_family-deafult; // fonts.sass
$nav_parent-font-family: $font_family-primary; // fonts.sass
$nav_font-size: 15px; // $font_size-default; // fonts.sass
$nav_font-color: $font_colour-default; // var.sass
$nav_font-color-hover: $nav_font-color; // var.sass

// border
$nav_border-top: 0.5px solid $border_colour-default; // 0 none
$nav_border-bottom: 0.5px solid $border_colour-default; // 0 none

// colours 
$nav_background: transparent;

$nav-lv1-font-size: 15px;
$nav-lv2-font-size: 15px;
$nav-lv3-font-size: 18px;
$nav-lv4-font-size: 18px;

$active-nav-indent: 54px;

$parent_icon: "\e907";
$parent_icon_mobile: "\e909";
$parent_icon_mobile_back: "\e904";

$mobile_link-line-height: 40px;
$mobile_nav-background-color: #fff;

// Mobile slide menu variables 
$last-level-to-slide: 1;
/*
1- General Navigation Styles

*/
/* ============================================ *
 * 1- General Navigation Styles
 * ============================================ */
.nav-sections {

    @include make-col-ready;
    @include make-col(5);

    @include media-breakpoint-up(md) {
        @include make-col(8);
    }
    @include media-breakpoint-up(lg) {
        @include make-col(6);
    }
    
    position: static;

    display: flex;
    align-items: center;

    font-size: $nav_font-size;
    color: $nav_font-color;
    font-family: $nav_font-family;

    // @include media-breakpoint-down(md) {
    //     font-size: 13px;
    // }
}



// Mobile nav toggle
.nav-toggle {
    cursor: pointer;
    font-size: 0;
    position: relative;
    z-index: 14;
    text-align: right;

    display: none;
    // @include make-col-ready;
    // @include make-col(1);
    @include media-breakpoint-down(sm) {
        display: flex;
        margin-left: 25px;
    }
    
    span:before {
        @include font-icon('\e916');
        font-size: 16px;
        // background: #f00;
        // width: 100%;
        // height: 20px;
    }
}

/* ============================================ *
 * Mobile
 * ============================================ */
@include media-breakpoint-down(sm) {  
    .nav-sections-item-title {
        text-align: right;
        padding: 10px 15px;
        line-height: normal;  
        position: absolute;
        right: 0;      
        z-index: 99;
        &:after {
            @include font-icon("\e924");
        }
        .nav-sections-item-switch {
            @include visuallyhidden();
        }
    }  
    .nav-sections {
        -webkit-overflow-scrolling: touch;
        height: 100%;
        left: 0;
        overflow-y: auto;
        position: fixed;   
        top: 0;
        width: 80%;
        text-align: left;
        padding: 0;
        background: $mobile_nav-background-color;
        z-index: 9999;
        transition: all ease-in 0.3s;
        transform: translateX(-100%);
    }    
    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;
        .page-wrapper {
            height: 100%;
            left: 0;
            overflow: hidden;
            position: relative;
            transition: all ease-in 0.3s;
        }
        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }  

    .nav-open {
        /*  
        // Content Slide when menu open
        .page-wrapper {
            left: 80%;
            //left: calc(~'100% - ${active-nav-indent}');
        }
        */
        .nav-sections {
            transform: translateX(0);
            z-index: 999;
        }
        .nav-toggle {
            &:after{
                background: rgba(0, 0, 0, 0.5);
                content: '';
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }
        .navigation {
            transition: all ease-in-out 0.3s;
            li.level0 {    
                @for $i from 1 through 50 {
                  &:nth-child(#{$i}) > a {
                    animation-delay: 0.1s * $i;
                  }
                }                  
                > a {
                    animation-name: slideIn;
                    animation-duration: 800ms;
                    animation-fill-mode: both;
                    animation-timing-function: ease-in-out;                  
                } 
            } 
            @for $j from 0 through 50 {
              &.slide-level-#{$j} {
                transform: translateX(calc(#{$j+1} * -100%));
              }
            }        
        }           
    } 
    // Inner Menu style
    .navigation {
        ul {
            padding: 80px 0;
            &.submenu {
                position: fixed;
                width: 100%;
                top: 0;
                //left: 100%;
                bottom: 0;
                background: #fff;
                z-index: 9;
                @for $j from 0 through 50 {
                  &.level#{$j} {
                    left: calc(#{$j+1} * 100%);
                  }
                }                  
            }  
            &.submenu-active {
                display: block !important;
                > li {    
                    @for $i from 1 through 50 {
                      &:nth-child(#{$i}) > a {
                        animation-delay: 0.1s * $i;
                      }
                    }                  
                    > a {
                        animation-name: slideIn;
                        animation-duration: 800ms;
                        animation-fill-mode: both;
                        animation-timing-function: ease-in-out;                  
                    } 
                }                 
            }             
        }        
        li {
            display: block;
            clear: both;   
            > a {
                display: block;
                padding: 0 20px;
                line-height: 40px;
            } 
            &:nth-child(even) {
                background: #f2f2f2;
            }                                  
        }
    }    
    .level-back {
        display: block;
        opacity: 0;
        position: absolute;
        top: 10px;
        left: 20px;
        z-index: -1;
        transition: all ease-in-out 0.3s;
        &.active {
            opacity: 1;
            z-index: 99;
        }
    }      
}
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(30%);  
  }
  50% {
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
}



/* ============================================ *
 * Desktop
 * ============================================ */
@include media-breakpoint-up(md) {
    .level-back {
        display: none;
    }

    .nav-wrap {
        
        
        position: absolute;
        visibility: hidden;
        left: 0;
        top: 100%;
        width: 100%;
        
        z-index: 99999;
        background: #fff;

        border-top: $border-default;
        border-bottom: $border-default;
        border-color: $border_colour-light;
        
        transition: 0.1s 0.1s;

        .container {
            @include make-container();
            
            display: flex;
            justify-content: flex-start;
            align-items:flex-start;
            justify-content: space-between;

        }              
    }

    .submenu {
        display: flex !important;
        flex-grow: 1;
        flex-wrap: wrap;
        background: #fff;
        padding: 50px 0 0;
        margin: 0 -34px;
        text-align: center;

        
    }    
    .nav-toggle {
        display: none;
    }
    .nav-sections-item-title {
        display: none;
    }
    .navigation {
        &:empty {
            display: none;
        }
        > ul {
            margin: 0 auto;
            float: none;
            text-align: center;
            padding: 0;
            //position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
        }      
        // Nav level 1 - parent
        li.level0 {
             
            display: inline-block;
            padding : 10px 15px;
            > a {
                display: inline-block;
                //line-height: 86px;
            }
            &:hover > .nav-wrap {
                display: block;
                visibility: visible;
                transition: 0.1ms 0.1ms;
            }
            > .level-top {

                &:hover, &.ui-state-focus {
                    span {
                        background: linear-gradient(180deg,rgba(255,255,255,0) 50%, #FFE8CB 50%);
                    }

                }
            }

            &.active,
            &.has-active {
                > .level-top {

                }
            }
            &.parent:hover > .submenu {
                overflow: visible !important;
            }

            > .submenu {
                display: none;
                left: 0 !important;
                margin: 0 !important;
                padding: 0;
                position: absolute;
                z-index: 1;
                background: #fff;
                width: 100%;
                padding: 10px;
                a {
                    display: block;
                    //line-height: inherit;
                    &:hover,
                    &.ui-state-focus {

                    }
                }

                .active > a {

                }

                .submenu {
                    display: block !important;
                    top: 0 !important;
                    left: 0 !important;
                    // top: 0 !important;
                    // left: 100% !important;
                }

                .submenu-reverse{
                    // left: auto !important;
                    // right: 100%;
                }
            }
        }
        // Nav level 1 - first child
        li.level1 {
            position: relative;
            flex: 0 0 20%;
            margin-bottom: 40px;
            padding: 0 34px; 

            > a {
                display: flex;
                align-items: center;
                flex-direction: column;
                > span {
                    display: block;
                    margin: 10px 0 0;
                }
            }
        }
    }

    .panel.header {
        .links,
        .switcher {
            display: inline-block;
        }
    }
//}
}
